import { ThemeProvider } from '@emotion/react';
import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { MenuHeaderEmbed } from '@jane/shared-ecomm/components';
import { useCurrentStoreId } from '@jane/shared-ecomm/hooks';
import {
  AuthenticationProvider,
  UserPreferencesProvider,
} from '@jane/shared-ecomm/providers';
import { trackCartClick } from '@jane/shared-ecomm/tracking';
import {
  EmbeddedHeader,
  LoadingWrapper,
  MfaModal,
} from '@jane/shared/components';
import {
  useInProgressReservations,
  useJaneUser,
} from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type { Store } from '@jane/shared/models';
import { MfaProvider, useEcommApp } from '@jane/shared/providers';
import {
  Box,
  Flex,
  ReeferThemeProvider,
  ToastProvider,
  useMobileMediaQuery,
} from '@jane/shared/reefer';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import { StoreScripts } from '@jane/shared/scripts';
import {
  PaginationCountProvider,
  calcNumberOfCartItems,
  getEcommPaths,
  parseSearch,
} from '@jane/shared/util';

import { PendingCartDrawer } from '../../components/cart/pendingCartDrawer/pendingCartDrawer';
import { useCustomerDispatch } from '../../customer/dispatch';
import {
  openCart,
  updateQueryPromoCode,
  updateTags,
} from '../../customer/redux/cart';
import { whoamiSuccess } from '../../customer/redux/customer';
import { setPartner, verifyStore } from '../../customer/redux/embeddedApp';
import type { NoStore } from '../../customer/redux/store';
import { isNoStore } from '../../customer/redux/store';
import { setPreviousPath } from '../../customer/redux/users';
import { useCustomerSelector } from '../../customer/selectors';
import { useAuth } from '../../hooks/useAuth/useAuth';
import { useEmbeddedOptionsListener } from '../../hooks/useEmbeddedOptionsListener';
import { useForceClientRefresh } from '../../hooks/useForceClientRefresh';
import { useRecordPageView } from '../../hooks/useRecordPageView';
import { getStoreTheme } from '../../lib/getTheme';
import { paths } from '../../lib/routes';
import { get } from '../../redux-util/selectors';
import { DEFAULT_THEME } from '../../theming';
import { IdentifyCustomer } from '../../tracking';
import { SetJaneDeviceId } from '../SetJaneDeviceId';
import { CartAlertBrandSpecialChangesProvider } from '../cartAlerts/cartAlertBrandSpecialChangesProvider';
import CheckAuthTimer from '../checkAuthTimer';
import ServerNotifications from '../notifications/server';
import { ScrollPositionProvider } from '../storeDetail/scrollPositionProvider';

const buildTheme = (store: Store | NoStore) => {
  if (isNoStore(store) || !store.white_label_enabled) {
    return DEFAULT_THEME;
  }
  return getStoreTheme(store);
};

export const EmbeddedAppContainer = () => {
  const myHigh = useFlag(FLAGS.myHighMenu);
  useForceClientRefresh();
  useRecordPageView();
  const dispatch = useCustomerDispatch();
  const location = useLocation();
  const isMobile = useMobileMediaQuery({});
  const { theme } = useEmbeddedOptionsListener();
  const { isPartnerHosted } = useRuntimeConfig();
  const authentication = useAuth();
  const initialStoreId = useCurrentStoreId();
  const { appMode } = useEcommApp();
  const { isLoadingStore, store } = useCustomerSelector(get('store'));
  const { disableAuthFeatures, isVerifying, partnerId } = useCustomerSelector(
    get('embeddedApp')
  );
  const cart = useCustomerSelector((state) => state.cart.cart);
  const hidePrices = useCustomerSelector(({ store: { store } }) =>
    isNoStore(store) ? true : store.hide_prices
  );

  const previousPathRef = useRef(location.pathname);

  const storePathProps =
    'id' in store
      ? { id: store?.id, name: store?.name, slug: store?.url_slug }
      : { id: '', name: '', slug: '' };

  const ecommPaths = getEcommPaths({
    appMode: 'embedded',
    storePathProps,
  });

  const { data: janeUser, isLoading: isLoadingUser } = useJaneUser();
  const authenticated = janeUser?.user?.authenticated;

  const { data: inProgressReservations } = useInProgressReservations({
    enabled: authenticated,
    storeId: initialStoreId,
  });

  // TODO: Remove this once we fully remove the customer redux state
  useEffect(() => {
    if (janeUser?.user) {
      dispatch(whoamiSuccess(janeUser?.user));
    }
  }, [janeUser?.user]);

  useEffect(() => {
    dispatch(setPreviousPath(previousPathRef.current));
    previousPathRef.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    const query = parseSearch(window.location.search);
    dispatch(updateTags(query));
    dispatch(updateQueryPromoCode(query['promo_code']));

    dispatch(setPartner({ id: initialStoreId, name: '' }));
    dispatch(
      verifyStore({
        storeId: initialStoreId,
        options: {
          forceEmbed: !!query['embed'],
          isPartnerHosted,
        },
      })
    );
  }, []);

  const onCartClick = () => {
    dispatch(openCart());
    trackCartClick();
  };

  const isLoading = isLoadingStore || isVerifying || isLoadingUser;

  return (
    <LoadingWrapper isLoading={isLoading} variant="full-page">
      <StoreScripts storeId={partnerId} />
      <CheckAuthTimer />
      <SetJaneDeviceId />
      <>
        <IdentifyCustomer
          app="embedded"
          appStoreId={initialStoreId}
          queryParams={parseSearch(window.location.search)}
        />
        <PaginationCountProvider>
          <ScrollPositionProvider>
            <ReeferThemeProvider
              renderProvider={(theme, children) => (
                <ThemeProvider theme={theme}>{children}</ThemeProvider>
              )}
              theme={theme || buildTheme(store)}
            >
              <ToastProvider position={isMobile ? 'top-middle' : 'top-right'}>
                <MfaProvider isEmbed>
                  <UserPreferencesProvider>
                    <AuthenticationProvider value={{ ...authentication }}>
                      <CartAlertBrandSpecialChangesProvider>
                        <ServerNotifications />
                        <Flex pb={24} flexDirection="column" grow>
                          <PendingCartDrawer />
                          <MfaModal />
                          {myHigh && (
                            <MenuHeaderEmbed
                              py={24}
                              disableAuthFeatures={disableAuthFeatures}
                              inProgressReservations={
                                inProgressReservations?.length
                              }
                              onCartClick={onCartClick}
                              paths={ecommPaths}
                              productsInCart={calcNumberOfCartItems(cart)}
                              store={store as Store}
                            />
                          )}

                          {!myHigh && (
                            <EmbeddedHeader
                              appMode={appMode}
                              cart={cart}
                              disableAuthFeatures={disableAuthFeatures}
                              hidePrices={hidePrices}
                              inProgressReservations={inProgressReservations}
                              onCartClick={onCartClick}
                              partnerId={partnerId}
                              store={
                                isNoStore(store) ? undefined : (store as Store)
                              }
                              routeAppMode={paths.getAppMode()}
                              routePartnerHostedConfig={paths.getPartnerHostedConfig()}
                            />
                          )}
                          <Box>
                            <LoadingWrapper isLoading={isLoading}>
                              <Outlet />
                            </LoadingWrapper>
                          </Box>
                        </Flex>
                      </CartAlertBrandSpecialChangesProvider>
                    </AuthenticationProvider>
                  </UserPreferencesProvider>
                </MfaProvider>
              </ToastProvider>
            </ReeferThemeProvider>
          </ScrollPositionProvider>
        </PaginationCountProvider>
      </>
    </LoadingWrapper>
  );
};
