import { createRoot } from 'react-dom/client';

import { bootstrap, configureCustomerStore } from '@jane/monolith/embed';
import { LegacyAppWrapper } from '@jane/shared-ecomm/components';
import { ResizeIframeProvider } from '@jane/shared-ecomm/providers';
import { config } from '@jane/shared/config';
import { asyncFlagProvider } from '@jane/shared/feature-flags';
import {
  EcommAppProvider,
  ScrollRestorationProvider,
} from '@jane/shared/providers';

import App from './main';

const store = configureCustomerStore();

const container = document.getElementById('app') as HTMLElement;
const root = createRoot(container);

(async () => {
  const FlagProvider = await asyncFlagProvider(config.launchDarklyClientID);
  await bootstrap();
  root.render(
    <EcommAppProvider appMode="embedded">
      <FlagProvider>
        <LegacyAppWrapper store={store}>
          <ResizeIframeProvider>
            <ScrollRestorationProvider isIframe>
              <App />
            </ScrollRestorationProvider>
          </ResizeIframeProvider>
        </LegacyAppWrapper>
      </FlagProvider>
    </EcommAppProvider>
  );
})();
