import type { JaneSearchState } from '@jane/search/types';
import { pluralize } from '@jane/shared/util';

export function productOrStoreSearchSummary<T>({
  cityState,
  count,
  showLocation = true,
  searchState,
  searchWithLocation,
  searchType,
}: {
  cityState?: string;
  count: number;
  searchState: JaneSearchState<T>;
  searchType: 'product' | 'store';
  searchWithLocation: boolean;
  showLocation?: boolean;
}) {
  const { searchText: query } = searchState;
  const queryWords = query && query.length ? ` for "${query}"` : '';
  const userLocation =
    showLocation && searchWithLocation && cityState
      ? `near ${cityState}`
      : `in all cities`;
  const locationText = showLocation ? `${userLocation}` : '';

  return `Found ${pluralize({
    number: count,
    noun: searchType,
  })}${queryWords} ${locationText}`;
}
