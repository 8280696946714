import { config } from '@jane/shared/config';
import type { Id, MedRec } from '@jane/shared/models';

import { request } from '../../lib/request';

const url = (id: Id) => `${config.apiPath}/operators_stores/${id}`;

export const OperatorStoresSource = {
  get(id: Id, params: RequestInit & { requireAuth?: boolean }) {
    return request(
      url(id),
      {
        method: 'GET',
      },
      params
    );
  },

  getAvailableFufillmentModes({
    id,
    lat,
    long,
    medOrRec,
    productId,
  }: {
    id: Id;
    lat?: number;
    long?: number;
    medOrRec?: MedRec;
    productId?: number;
  }) {
    const url = `${config.apiPath}/operators_stores/`;
    return request(
      url,
      {
        method: 'GET',
      },
      {
        operator_id: id,
        med_rec: medOrRec,
        lat,
        long,
        product_id: productId,
      }
    );
  },
};
