import { useRef } from 'react';
import { useRoutes } from 'react-router-dom';

import {
  CustomerGlobal,
  MediaQueryProvider,
  Modals,
  SkipLinks,
  initAlgoliaInsights,
  setPartnerHostedConfig,
  useResizeIframe,
  useScrollReset,
} from '@jane/monolith/embed';
import { config } from '@jane/shared/config';
import { maybeSetPublicPath } from '@jane/shared/public-path';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import { initMixpanel, initTrackJS, loadTimezoneData } from '@jane/shared/util';

import { routes } from './app/routes';

maybeSetPublicPath();
initTrackJS();
initMixpanel(config.mixpanelCustomerToken, {
  userSessionHost: config.mixpanelUserSessionApiHost,
  useLocalStorage: true,
});
loadTimezoneData();
initAlgoliaInsights();

const App = () => {
  useScrollReset();
  const { isPartnerHosted, partnerHostedPath } = useRuntimeConfig();
  const routing = useRoutes(routes(isPartnerHosted, partnerHostedPath));
  setPartnerHostedConfig({ isPartnerHosted, partnerHostedPath });
  const containerElement = useRef<any>(null);
  useResizeIframe(containerElement);

  return (
    <div ref={containerElement}>
      <CustomerGlobal />
      <MediaQueryProvider>
        <SkipLinks />
        <Modals />
        {routing}
      </MediaQueryProvider>
    </div>
  );
};

export default App;
